//GraphQL API
export const GOOGLE_API_KEY = process.env.GOOGLE_API_KEY || '';
export const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_MAPS_CLIENT_ID || '';
export const RETAILER_LOCATIONS_ENDPOINT =
  process.env.NEXT_PUBLIC_RETAILER_LOCATIONS_ENDPOINT || '';
export const DIPLOMAT_RETAILERS_ENDPOINT =
  process.env.NEXT_PUBLIC_DIPLOMAT_RETAILERS_ENDPOINT || '';
export const ADDRESS_COORDS_ENDPOINT =
  process.env.NEXT_PUBLIC_ADDRESS_COORDS_ENDPOINT || '';
export const PLACE_COORDINATES_ENDPOINT =
  process.env.NEXT_PUBLIC_PLACE_COORDINATES_ENDPOINT || '';
export const AOR_RETAILERS_ENDPOINT =
  process.env.NEXT_PUBLIC_AOR_RETAILERS_ENDPOINT || '';

export const { COMMERCIAL_PARTNER_API_URL, COMMERCIAL_PARTNER_API_KEY } =
  process.env;
