import React, { useEffect, useState } from 'react';
import { Icon, IconButton } from '@volvo-cars/react-icons';
import { Checkbox } from '@volvo-cars/react-forms';
import { Track } from '@volvo-cars/tracking';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { useRetailers } from '../providers/RetailersProvider';
import { useStore } from '../providers/StoreProvider';
import { translateCapability } from '../../utils/translateCapability';
import styles from './FilterSheet.module.css';

type FilterSheetProps = {
  onClose: () => void;
  dialogProps: { ref: React.RefObject<HTMLDialogElement> };
  resetToggle?: boolean;
};

const FilterSheet = ({
  onClose,
  dialogProps,
  resetToggle,
}: FilterSheetProps) => {
  const translate = useSharedComponentsTranslate();
  const { dispatch } = useStore();

  const {
    setActiveCapabilityFilters,
    activeCapabilityFilters,
    availableCapabilityFilters,
  } = useRetailers();

  const [filtersChanged, setFiltersChanged] = useState(false);
  const [checkedCapabilites, setCheckedCapabilites] = useState(
    activeCapabilityFilters,
  );

  useEffect(() => {
    const checkedFilterEqualToActiveFilters =
      JSON.stringify(activeCapabilityFilters.sort()) ===
      JSON.stringify(checkedCapabilites.sort());

    setFiltersChanged(!checkedFilterEqualToActiveFilters);
  }, [activeCapabilityFilters, checkedCapabilites]);
  useEffect(() => {
    setCheckedCapabilites(activeCapabilityFilters);
  }, [resetToggle, setCheckedCapabilites, activeCapabilityFilters]);

  return (
    <dialog
      data-anchor="start"
      className="sheet w-sm"
      aria-labelledby="filter-dialog-title"
      {...dialogProps}
    >
      <header slot="header">
        <div slot="close">
          <IconButton
            aria-label={translate('RetailerSelector.search.close') || 'Close'}
            bleed
            icon="x"
            onClick={onClose}
            variant="clear"
          />
        </div>
        <div slot="back">
          <IconButton
            aria-label={translate('RetailerSelector.search.back') || 'Back'}
            bleed
            icon="chevron-back"
            onClick={onClose}
            variant="clear"
          />
        </div>
        <h2 className="font-medium" id="filter-dialog-title">
          {translate('RetailerSelector.search.retailerFilters') ||
            'Retailer filters'}
        </h2>
      </header>
      <article slot="main">
        <div className="flex gap-8 items-center pb-24">
          <Icon icon="loop-backwards" size={16} />
          <button
            className="link-inline"
            type="button"
            onClick={() => setCheckedCapabilites([])}
          >
            {translate('RetailerSelector.search.clearFilters') ||
              'Clear filters'}
          </button>
        </div>
        <h2 className="font-20 font-medium mb-16">
          {translate('RetailerSelector.search.retailerServices') ||
            'Retailer services'}
        </h2>
        {availableCapabilityFilters.map((capability) => (
          <Checkbox
            key={capability}
            label={translateCapability(capability, translate)}
            name={capability}
            className={`${styles.checkbox} items-center`}
            checked={checkedCapabilites.includes(capability)}
            onChange={(test) => {
              const isChecked = test.target.checked;
              const updatedCapabilities = isChecked
                ? checkedCapabilites.concat(capability)
                : checkedCapabilites.filter((cap) => cap !== capability);

              setCheckedCapabilites(updatedCapabilities);
            }}
          />
        ))}
      </article>
      <footer slot="footer" className="flex justify-center gap-16 flex-wrap">
        <Track eventLabel={`apply-filters|${checkedCapabilites.join('|')}`}>
          <button
            className="button-filled"
            type="button"
            disabled={!filtersChanged}
            onClick={() => {
              setActiveCapabilityFilters(checkedCapabilites);
              onClose();
              dispatch({ type: 'SET_RETAILERSLIST_VISIBLE', payload: true });
            }}
          >
            {translate('RetailerSelector.search.applyFilters') ||
              'Apply filters'}{' '}
            ({checkedCapabilites.length})
          </button>
        </Track>
        <button
          className="button-outlined"
          type="button"
          disabled={!filtersChanged}
          onClick={() => setCheckedCapabilites(activeCapabilityFilters)}
        >
          {translate('RetailerSelector.search.cancel') || 'Cancel'}
        </button>
      </footer>
    </dialog>
  );
};

export default FilterSheet;
