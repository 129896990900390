import React from 'react';
import { getDealerId } from '../../../utils/volvoTrackingHelpers';
import { useStore } from '../../providers/StoreProvider';
import { SiteSlug } from '@volvo-cars/market-sites';
import { RetailerCard } from '../RetailerCard';
import { Retailer } from '../../types/retailer';
import { useRetailerCardInfo } from '../useRetailerCardInfo';
import { getRetailerLinks } from '../utils/getRetailerLinks';

type ContentProps = { retailer: Retailer; siteSlug: SiteSlug };

export const Content = ({ retailer, siteSlug }: ContentProps) => {
  const { firstDayOfWeek } = useStore();
  const { distance, addressHref, addressLines, name } =
    useRetailerCardInfo(retailer);
  const links = getRetailerLinks(retailer, siteSlug);

  return (
    <div data-testid="dealer:retailerContainer">
      <div className="flex-col gap-24">
        <RetailerCard.Header name={name} distance={distance} />
        <RetailerCard.Address
          ariaLabelRetailerName={name}
          url={addressHref}
          addressLines={addressLines}
        />
        <RetailerCard.CapabilityPills capabilities={retailer.capabilities} />
        <RetailerCard.CapabilitySelection
          {...retailer}
          firstDayOfWeek={firstDayOfWeek}
        />
      </div>
      {links.length > 0 && (
        <>
          <div className="border-ornament border-b h-24" />
          <RetailerCard.LinksGroup
            dealerId={getDealerId(retailer)}
            dealerInfo={name}
            links={links}
          />
        </>
      )}
    </div>
  );
};
