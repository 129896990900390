export const ALL_CAPABILITIES = [
  'sales',
  'service',
  'collision center',
  'diplomat',
  'test drive',
  'used car sales',
] as const;

export type Capability = (typeof ALL_CAPABILITIES)[number];
