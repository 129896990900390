import React, { useEffect } from 'react';
import { useDialog } from '@volvo-cars/react-headless';
import { useBreakpoints } from '@vcc-www/hooks';
import { useSelectorSettings, useStore } from 'src/hooks';
import styles from './SearchPositioned.module.css';
import { ExpandedCardDialog } from '../retailerCard/ExpandedCard';
import {
  RetailerCardMaxi,
  RetailerSearch,
  SearchFrame,
} from '@vcc-package/retailer-selector';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { isValidSiteSlug } from '@volvo-cars/market-sites';
import { ErrorMessages } from 'src/constants/errorMessages';
import { logError } from 'src/utils/logError';

type SearchPositionedProps = React.PropsWithChildren<{
  view: 'map' | 'list';
  scrollRef?: React.RefObject<HTMLDivElement>;
}>;

export const SearchBlockWidth = 420;

const SearchPositioned = ({
  view,
  children,
  scrollRef,
}: SearchPositionedProps) => {
  // Needed to toggle status of expanded view modal on mobile / non mobile view.
  // eslint-disable-next-line vcc-www/use-breakpoints
  const { untilL, fromL } = useBreakpoints();
  const {
    expandedCardVisible,
    selectedRetailer,
    retailersListVisible,
    expandedCardEnabled,
    dispatch,
  } = useStore();
  const { useSelector } = useSelectorSettings();
  const { siteSlug } = useCurrentMarketSite();
  const onDialogToggle = ({ open }: { open: boolean }) => {
    if (!open) closeExpandedCard();
  };
  const { dialogProps, showDialog, closeDialog } = useDialog({
    onToggle: onDialogToggle,
  });

  useEffect(() => {
    const mapElement = document.querySelector('[aria-roledescription="map"]');

    if (!expandedCardEnabled) return;
    if (expandedCardVisible && untilL) {
      // This is fix for avoiding autofocus on the dialog backbutton. Because the map is in focus, it triggers a safaribug that is fixed in dialogcode, see https://github.com/volvo-cars/design-system-web/blob/master/packages/react-headless/src/dialog.ts#L221
      // You can still get the focusring when tabbing, because the pin is in focus before opening the dialog and not the map
      mapElement instanceof HTMLElement &&
        mapElement?.matches(':focus') &&
        mapElement.blur();
      mapElement instanceof HTMLElement &&
        mapElement?.matches(':focus-visible') &&
        mapElement.blur();
      showDialog();
    } else {
      mapElement?.setAttribute('tabindex', '0');
      closeDialog();
    }
  }, [
    expandedCardVisible,
    showDialog,
    closeDialog,
    untilL,
    expandedCardEnabled,
  ]);

  if (!isValidSiteSlug(siteSlug)) {
    logError(
      `${ErrorMessages.SEARCH_POSITIONED_INVALID_SITE_SLUG}: siteSlug is ${siteSlug}`,
    );
    return null;
  }

  const closeExpandedCard = () => {
    dispatch({ type: 'SET_EXPANDED_CARD_VISIBLE', payload: false });
  };

  const showingMaxiCard =
    expandedCardVisible && selectedRetailer && expandedCardEnabled && fromL;
  const searchFrameHeader = showingMaxiCard ? (
    <RetailerCardMaxi onClose={closeExpandedCard}>
      <RetailerCardMaxi.Content
        retailer={selectedRetailer}
        siteSlug={siteSlug}
      />
    </RetailerCardMaxi>
  ) : (
    <RetailerSearch showFilter={!useSelector} />
  );

  const searchFrameMain = !showingMaxiCard && retailersListVisible && (
    <>
      {!!children && (
        <div className={` until-lg:hidden dl-max-h-inherit`}>{children}</div>
      )}
    </>
  );

  return (
    <>
      <div
        className={`${styles.searchWrapper} ${view === 'map' ? styles.searchWrapperMap : 'md:mt-24'} flex-col dl-z-1 w-full lg:min-h-0 mx-auto lg:m-auto sticky top-0`}
        style={{ '--search-block-width': `${SearchBlockWidth}px` }}
      >
        <SearchFrame
          scrollRef={scrollRef}
          header={searchFrameHeader}
          main={searchFrameMain}
        />
        {view === 'list' && (
          <div className="flex-grow lg:hidden py-24 px-24 overflow-auto scrollbar-none">
            {children}
          </div>
        )}
      </div>
      <ExpandedCardDialog
        dialogProps={dialogProps}
        retailer={selectedRetailer}
        onClose={closeExpandedCard}
        siteSlug={siteSlug}
      />
    </>
  );
};

export default SearchPositioned;
