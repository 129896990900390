import { createContext, useContext } from 'react';
import { ConfigurationKey } from '../constants/marketConfigs';
import { Capability } from '../types/retailerCapabilities';

export type MarketGeneralConfigurationsObject = {
  [key in ConfigurationKey]: boolean;
};

export type MarketConfigurations = MarketGeneralConfigurationsObject & {
  disabledFilters: Capability[];
};

export const initialMarketConfig: MarketConfigurations = {
  mapReboundMaxRadius1000km: false,
  useAorRetailersAndZipCodeSearch: false,
  coordinatesMapLink: false,
  disabledFilters: [],
};

export const MarketConfigContext =
  createContext<MarketConfigurations>(initialMarketConfig);

export const useMarketConfig = () => useContext(MarketConfigContext);
