export enum ErrorMessages {
  TEST_ERROR = 'TEST_ERROR',
  /* RETAILER CARD */
  FORMAT_DISTANCE_INVALID_LOCALE = 'FORMAT_DISTANCE_INVALID_LOCALE',
  MAXI_CARD_INVALID_RETAILER = 'MAXI_CARD_INVALID_RETAILER',
  /* UTILS */
  INVALID_CAPABILITY = 'INVALID_CAPABILITY',
  /* RETAILER FETCHING */
  AOR_RETAILER_CONTRADICTING_INPUT_PARAMETERS = 'AOR_RETAILER_CONTRADICTING_INPUT_PARAMETERS',
  NO_INITIAL_RETAILERS = 'NO_INITIAL_RETAILERS',
  /* PROVIDERS */
  USE_RETAILERS_MUST_BE_USED_IN_RETAILERS_PROVIDER = 'USE_RETAILERS_MUST_BE_USED_IN_RETAILERS_PROVIDER',
}
