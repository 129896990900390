import MapErrorBoundary from './MapErrorBoundary';
import { Map } from './Map';

type MapPositionedProps = {
  view: 'map' | 'list';
};

const MapPositioned = ({ view }: MapPositionedProps) => (
  <>
    {/* 
      Two statements needed, only rendering desktop in view map will not allow for toggling to list view -> resizing screen 
      Rendering map with checking view as pins are not loading correctly on tablet view unless this statement (bug in useCluster, wrong latestMapChangeEventValue is sent)
      Once that bug is solved, one statement without view==="map" is required
     */}
    {view === 'map' && (
      <div className="h-full -mt-8 md:mt-0 lg:hidden">
        <MapErrorBoundary>
          <Map />
        </MapErrorBoundary>
      </div>
    )}
    <div className="h-full until-lg:hidden">
      <MapErrorBoundary>
        <Map />
      </MapErrorBoundary>
    </div>
  </>
);

export default MapPositioned;
