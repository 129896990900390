import { DictionaryItemTypes } from '@vcc-www/shared-dictionaries/DictionaryItemTypes';
import { SiteSlug } from '@volvo-cars/market-sites';
import { Retailer } from '../../types/retailer';
import { enabledSiteslugs } from '../../constants/enabledSiteslugs';

/**
 * Returns an object array of a retailer's available links. The links that are checked:
 *  - newCarLocator (New Inventory)
 *  - usedCarLocator (Used cars locator)
 *  - workshop (Schedule Service)
 *  - shop (New Inventory for uk)
 *  - test-drive (Test drive) Non RDM field but hardcoded for all market
 *  - quote-request (Get help with your purchase / Get a quote) Not RDM field but hardcoded for all market
 *  - url (Visit Website)
 */

type FilterKeys =
  | 'test-drive'
  | 'quote-request'
  | 'new-car-locator'
  | 'used-car-locator'
  | 'schedule-service'
  | 'shop'
  | 'visit-website';
export type RetailerLink = {
  url: string;
  translationKey: keyof DictionaryItemTypes;
  eventLabel: string;
  defaultLabel: string;
  configurationKey: FilterKeys;
};

type UnfilteredRetailerLink = Omit<RetailerLink, 'url'> & {
  url: string | null | undefined;
};

type LinkMarketConfiguration = {
  configurationKey: FilterKeys;
  disabledSiteSlugs: SiteSlug[];
};

const USE_PURCHASE_HELP_MARKETS: SiteSlug[] = ['uk', 'no', 'se', 'de', 'nl']; // is flip5 se disabled quote request

const marketLinkConfigurations: LinkMarketConfiguration[] = [
  {
    configurationKey: 'quote-request',
    disabledSiteSlugs: ['intl', 'ba', 'hr', 'us', 'il', 'si', 'se'],
  },
  {
    configurationKey: 'test-drive',
    disabledSiteSlugs: ['se', 'il', 'intl', 'us'],
  },
  {
    configurationKey: 'schedule-service',
    disabledSiteSlugs: [],
  },
  {
    configurationKey: 'new-car-locator',
    disabledSiteSlugs: ['uk'],
  },
  // Shop is corresponding to new-car-locator but in UK
  {
    configurationKey: 'shop',
    disabledSiteSlugs: enabledSiteslugs.filter((siteSlug) => siteSlug !== 'uk'),
  },
  {
    configurationKey: 'used-car-locator',
    disabledSiteSlugs: ['us'],
  },
  {
    configurationKey: 'visit-website',
    disabledSiteSlugs: ['pt'],
  },
];

export const getRetailerLinks = (retailer: Retailer, siteSlug: SiteSlug) => {
  const {
    newCarLocator,
    usedCarLocator,
    workshop,
    testDrive,
    quoteRequest,
    shop,
    url: visitWebsite,
  } = retailer;

  const unfilteredLinks: UnfilteredRetailerLink[] = [
    {
      url: testDrive,
      translationKey: 'RetailerSelector.retailerCard.testDrive',
      eventLabel: 'test-drive',
      defaultLabel: 'Book a test drive',
      configurationKey: 'test-drive',
    },
    {
      url: quoteRequest,
      translationKey: USE_PURCHASE_HELP_MARKETS.includes(siteSlug)
        ? 'RetailerSelector.retailerCard.helpPurchaseRequest'
        : 'RetailerSelector.retailerCard.quoteRequest',
      eventLabel: 'quote-request',
      defaultLabel: USE_PURCHASE_HELP_MARKETS.includes(siteSlug)
        ? 'Get help with your purchase'
        : 'Get a quote',
      configurationKey: 'quote-request',
    },
    {
      url: newCarLocator,
      translationKey: 'RetailerSelector.retailerCard.newCarLocator',
      eventLabel: 'new-car-locator',
      defaultLabel: 'New cars inventory',
      configurationKey: 'new-car-locator',
    },
    {
      url: shop,
      translationKey: 'RetailerSelector.retailerCard.newCarShop',
      eventLabel: 'new-car-locator',
      defaultLabel: 'View new car stock',
      configurationKey: 'shop',
    },
    {
      url: usedCarLocator,
      translationKey: 'RetailerSelector.retailerCard.usedCarLocator',
      eventLabel: 'used-car-locator',
      defaultLabel: 'Used cars inventory',
      configurationKey: 'used-car-locator',
    },
    {
      url: workshop,
      translationKey: 'RetailerSelector.retailerCard.scheduleService',
      eventLabel: 'schedule-service',
      defaultLabel: 'Schedule a service',
      configurationKey: 'schedule-service',
    },
    {
      url: visitWebsite,
      translationKey: 'RetailerSelector.retailerCard.visitWebsite',
      eventLabel: 'visit website',
      defaultLabel: 'Visit website',
      configurationKey: 'visit-website',
    },
  ];

  return unfilteredLinks
    .filter((l): l is RetailerLink => !!l.url)
    .filter((l) => {
      return !marketLinkConfigurations.find((config) => {
        return (
          l.configurationKey === config.configurationKey &&
          config.disabledSiteSlugs.includes(siteSlug)
        );
      });
    });
};
