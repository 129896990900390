import { logError } from 'src/utils/logError';
import { ErrorMessages } from 'src/constants/errorMessages';
import { Retailer, RetailerCardMaxi } from '@vcc-package/retailer-selector';
import { SiteSlug } from '@volvo-cars/market-sites';

type ExpandedCardDialogProps = {
  onClose: () => void;
  dialogProps: { ref: React.RefObject<HTMLDialogElement> };
  retailer: Retailer | null;
  siteSlug: SiteSlug;
};

const ExpandedCardDialog = ({
  onClose,
  retailer,
  dialogProps,
  siteSlug,
}: ExpandedCardDialogProps) => {
  const titleId = 'expanded-card-title';
  if (!retailer && dialogProps.ref.current?.open) {
    logError(
      `${ErrorMessages.EXPANDED_CARD_SHEET_INVALID_RETAILER}: retailer is ${retailer}`,
    );
  }
  return (
    <dialog
      className="dialog-large w-sm md:mt-48"
      aria-labelledby={titleId}
      {...dialogProps}
    >
      {retailer && (
        <>
          <header slot="header">
            <RetailerCardMaxi.Header onClose={onClose} />
          </header>
          <article slot="main">
            <RetailerCardMaxi.Content retailer={retailer} siteSlug={siteSlug} />
          </article>
        </>
      )}
    </dialog>
  );
};

export default ExpandedCardDialog;
